import { Pagination } from '../Pagination';
import { FilterRequest } from './FilterRequest';
import { ApiResponse } from './Response';

// #region list
export interface WarrantySealListRequest extends FilterRequest {
  dealerId: number
}
export interface WarrantySealListDetailResponse {
  'id': number,
  'runNoFrom': string,
  'qty': number,
  'bulkWarranty.deliveryDate': string,
  'regQty': number,
  'unregQty': number,
  'bulkWarrantySeal.createdBy.name': string,
}

interface WarrantySealListResult extends Pagination {
  items: Array<WarrantySealListDetailResponse>
}

export interface WarrantySealListResponse extends ApiResponse {
  result: WarrantySealListResult
}
// #endregion

// #region create
export interface CreateWarrantySealRequest {
  dealerId: number,
  deliveryDate: string,
  deliveryDateUi?: string | null,
  warrantySealQty: number | null
  runNo: number | null

  // #region update
  bulkWarrantySealId: number,
  // #endregion
  isSubmitting: boolean
}

export function createCreateWarrantySealRequest (): CreateWarrantySealRequest {
  return {
    'dealerId': 0,
    'deliveryDate': '',
    'deliveryDateUi': null,
    'warrantySealQty': null,
    'runNo': null,
    'bulkWarrantySealId': 0,
    'isSubmitting': false
  };
}

export function resetCreateWarrantySealRequest (form: CreateWarrantySealRequest): void {
  form.dealerId = 0;
  form.deliveryDate = '';
  form.deliveryDateUi = null;
  form.warrantySealQty = null;
  form.runNo = null;
  form.bulkWarrantySealId = 0;
}

export function updateCreateWarrantySealRequest (form: CreateWarrantySealRequest, details: WarrantySealListDetailResponse): void{
  resetCreateWarrantySealRequest(form);

  const dateInUTC = window.moment.utc(details['bulkWarranty.deliveryDate']).toDate();

  form.bulkWarrantySealId = details.id;
  form.deliveryDateUi = window.moment(dateInUTC).local().format('DD/MM/YYYY');
  form.warrantySealQty = details.qty;
  form.runNo = details.runNoFrom ? parseInt(details.runNoFrom.split(' - ')[0]) : 0;
}
// #endregion

// region unused list
export interface NonAssignedWarrantySeal {
  id: number,
  runNo: number
}

export interface WarrantySealList {
  warrantySeals: Array<NonAssignedWarrantySeal>
}

export interface NonAssignedWarrantySealListResponse extends ApiResponse {
  result: WarrantySealList
}

// #endregion
