






























































import DealerDetail from '@/components/DealerDetail.vue';
import DealerDualMenu from '@/components/DealerDualMenu.vue';
import SortableTable from '@/components/SortableTable.vue';
import ModalAddWarrantySeal from '@/components/modals/AddWarrantySeal.vue';
import ModalConfirmation from '@/components/modals/Confirmation.vue';
import {
  SortableTableDataConfig,
  SortableTableHeader
} from '@/types/SortableTableFormat';
import {
  initPagination,
  Pagination,
  updatePagination
} from '@/types/Pagination';
import {
  createCreateWarrantySealRequest,
  resetCreateWarrantySealRequest,
  updateCreateWarrantySealRequest,
  WarrantySealListDetailResponse,
  WarrantySealListRequest,
  WarrantySealListResponse
} from '@/types/api/WarrantySeal';
import {
  initTableSortAndFilter,
  updateTableSort,
  TableSortAndFilter
} from '@/types/TableSortAndFilter';
import {
  cleanupFilterRequest,
  createFilterRequest
} from '@/types/api/FilterRequest';
import debounce from 'debounce';
import Vue from 'vue';
import { ApiResponse } from '@/types/api/Response';
import { AxiosError } from 'axios';
const deleteWarrantySealConfirmationTitle = 'Delete Confirmation';
const deleteWarrantySealConfirmationMessage = '<p>Are you sure you want to delete this warranty seal?</p><p>This action cannot be undone!</p>';
export default Vue.extend({
  'name': 'DealerWarrantySealList',
  'components': {
    DealerDetail,
    DealerDualMenu,
    SortableTable,
    ModalAddWarrantySeal,
    ModalConfirmation
  },
  data () {
    const form = createCreateWarrantySealRequest();
    const pagination = initPagination();
    const tableFilter = initTableSortAndFilter();
    return {
      form,
      'formErrorMessage': '',
      pagination,
      tableFilter,
      'tableHeader': [
        {
          'key': 'runNoFrom',
          'display': 'Running No.',
          'width': 0,
          'cssClass': 'text-center',
          'isSortable': true,
          'sortOrder': 0,
          'isEmpty': false
        },
        {
          'key': 'qty',
          'display': 'Warranty Seal Qty',
          'width': 230,
          'cssClass': 'text-center',
          'isSortable': true,
          'sortOrder': 0,
          'isEmpty': false
        },
        {
          'key': 'bulkWarrantySeal.deliveryDate',
          'display': 'Delivery Date',
          'width': 220,
          'cssClass': 'text-center',
          'isSortable': true,
          'sortOrder': 0,
          'isEmpty': false
        },
        {
          'key': 'regQty',
          'display': 'Registered Qty',
          'width': 200,
          'cssClass': 'text-center',
          'isSortable': true,
          'sortOrder': 0,
          'isEmpty': false
        },
        {
          'key': 'unregQty',
          'display': 'Unregistered Qty',
          'width': 240,
          'cssClass': 'text-center',
          'isSortable': true,
          'sortOrder': 0,
          'isEmpty': false
        },
        {
          'key': 'bulkWarrantySeal.createdBy.name',
          'display': 'Created By',
          'width': 260,
          'cssClass': 'text-center',
          'isSortable': true,
          'sortOrder': 0,
          'isEmpty': false
        },
        {
          'key': '',
          'display': '',
          'width': 150,
          'cssClass': '',
          'isSortable': false,
          'sortOrder': 0,
          'isEmpty': true
        }
      ] as Array<SortableTableHeader>,
      'tableDataConfigs': [
        {
          'cssClass': 'text-center',
          'hasDisplay': true
        },
        {
          'cssClass': 'text-center',
          'hasDisplay': true
        },
        {
          'cssClass': 'text-center',
          'hasDisplay': true,
          'isDate': true,
          'dateTimeFormat': 'DD MMM YYYY'
        },
        {
          'cssClass': 'text-center',
          'hasDisplay': true
        },
        {
          'cssClass': 'text-center',
          'hasDisplay': true
        },
        {
          'cssClass': 'text-center',
          'hasDisplay': true
        },
        {
          'cssClass': 'text-nowrap',
          'hasDisplay': false,
          'buttons': [
            {
              'cssClass': 'btn-iris-table btn-iris-edit',
              'event': 'edit'
            },
            {
              'cssClass': 'btn-iris-table btn-iris-delete',
              'event': 'delete'
            }
          ]
        }
      ] as Array<SortableTableDataConfig>,
      'tableData': [] as WarrantySealListDetailResponse[],
      'debounceGetList': null as unknown,
      'deleteWarrantySeal': {
        'confirmationTitle': deleteWarrantySealConfirmationTitle,
        'confirmationMessage': deleteWarrantySealConfirmationMessage,
        'showConfirmButton': true,
        'discardButtonText': 'Discard',
        'id': -1
      }
    };
  },
  'computed': {
    appStateCurrentViewingDealerId (): number {
      return this.$store.state.app.currentViewingDealerId;
    }
  },
  'watch': {
    appStateCurrentViewingDealerId () {
      this.$nextTick(() => {
        this.getList();
      });
    },
    'tableFilter.search' () {
      this.$nextTick(() => {
        // @ts-expect-error: Ignore warning explicitly.
        this.debounceGetList();
      });
    },
    'pagination.page' () {
      this.$nextTick(() => {
        // @ts-expect-error: Ignore warning explicitly.
        this.debounceGetList();
      });
    }
  },
  mounted () {
    this.debounceGetList = debounce(this.getList, 500);

    const dealerId =
      this.$route.query && this.$route.query.dealer
        ? parseInt(this.$route.query.dealer as string)
        : this.appStateCurrentViewingDealerId;
    if (dealerId !== this.appStateCurrentViewingDealerId) {
      this.$store.commit('updateViewDealerId', dealerId);
    }
    this.$router
      .push({
        'query': {
          'dealer': dealerId + ''
        }
      })
      .catch((error) => {
        if (error.name !== 'NavigationDuplicated') {
          throw error;
        }
      });

    this.getList();
  },
  'methods': {
    getList () {
      let getRequest = createFilterRequest(this.pagination, this.tableFilter);
      getRequest = cleanupFilterRequest(getRequest);

      const getWarrantySealRequest: WarrantySealListRequest = Object.assign({
        'dealerId': this.appStateCurrentViewingDealerId
      }, getRequest);

      this.axios
        .get('warranty-seal/list', {
          'params': getWarrantySealRequest
        })
        .then(({ data }) => {
          const response = data as WarrantySealListResponse;
          this.tableData = response.result.items;

          const paginationUpdate = response.result as Pagination;
          updatePagination(this.pagination, paginationUpdate);
        });
    },
    addWarrantySeal () {
      resetCreateWarrantySealRequest(this.form);
      this.getNextWarrantySealRunNo();
      (this.$refs.modalAddWarrantySeal as any).create();
    },
    editWarrantySeal (rowIndex: number) {
      const warrantySeal = this.tableData[rowIndex];

      // this.axios.get(`product/${productId}`).then(({ data }) => {
      //   const response = data as ProductResponse;

      updateCreateWarrantySealRequest(this.form, warrantySeal);

      (this.$refs.modalAddWarrantySeal as any).update();
    },
    deleteWarrantySealFn (rowIndex: number) {
      this.deleteWarrantySeal.id = this.tableData[rowIndex]
        ? this.tableData[rowIndex].id
        : 0;

      this.deleteWarrantySeal.confirmationTitle = deleteWarrantySealConfirmationTitle;
      this.deleteWarrantySeal.confirmationMessage = deleteWarrantySealConfirmationMessage;
      this.deleteWarrantySeal.showConfirmButton = true;
      this.deleteWarrantySeal.discardButtonText = 'Discard';
      (this.$refs.deleteWarrantySealConfirmation as any).show();
    },
    confirmDeleteWarrantySeal () {
      (this.$refs.deleteWarrantySealConfirmation as any).hide(() => {
        this.axios.delete(`warranty-seal/${this.deleteWarrantySeal.id}`).then(({ data }) => {
          this.getList();

          this.deleteWarrantySeal.confirmationTitle = 'Success';
          this.deleteWarrantySeal.confirmationMessage = data.message;
          this.deleteWarrantySeal.showConfirmButton = false;
          this.deleteWarrantySeal.discardButtonText = 'Dismiss';
        }).catch((err:AxiosError) => {
          this.deleteWarrantySeal.confirmationTitle = 'Error';
          if (err && err.response) {
            const data = err.response.data as ApiResponse;
            this.deleteWarrantySeal.confirmationMessage = data.message;
          }
          this.deleteWarrantySeal.showConfirmButton = false;
          this.deleteWarrantySeal.discardButtonText = 'Dismiss';
        }).finally(() => {
          (this.$refs.deleteWarrantySealConfirmation as any).show();
        });
      });
    },
    discardDeleteWarrantySeal () {
      (this.$refs.deleteWarrantySealConfirmation as any).hide();
    },
    getNextWarrantySealRunNo () {
      interface NextRunningNoResponse{
        'nextRunNo': number,
      }

      interface ResultGetNextRunningNo extends ApiResponse{
        result: NextRunningNoResponse
      }

      this.axios.get('next-running-no').then(({ data }) => {
        const response = data as ResultGetNextRunningNo;

        this.form.runNo = response.result.nextRunNo;
      });
    },
    submitForm () {
      this.formErrorMessage = '';
      if (this.form.bulkWarrantySealId === 0) {
        this.form.isSubmitting = true;
        this.form.dealerId = this.appStateCurrentViewingDealerId;
        const clonedForm = Object.assign({}, this.form);
        delete clonedForm.deliveryDateUi;
        const momentDeliveryDate = window.moment(
          this.form.deliveryDateUi,
          'DD/MM/YYYY'
        );
        clonedForm.deliveryDate = momentDeliveryDate.toISOString();

        this.axios
          .post('warranty-seal', clonedForm)
          .then(() => {
            (this.$refs.modalAddWarrantySeal as any).hide();
          })
          .catch((err) => {
            if (
              err.response &&
            err.response.status === 422 &&
            err.response.data
            ) {
              const result = err.response.data.result;
              if (result) {
                (this.$refs.modalAddWarrantySeal as any).setErrors(result);
              }
            }

            if (err.response && err.response.data && err.response.data.message) {
              this.formErrorMessage = err.response.data.message;
            }
            if (this.formErrorMessage === '') {
              this.formErrorMessage = 'Error updating warranty seal.';
            }
          }).finally(() => {
            this.form.isSubmitting = false;
            this.getList();
          });
      } else {
        this.form.isSubmitting = true;
        const momentDeliveryDate = window.moment(
          this.form.deliveryDateUi,
          'DD/MM/YYYY'
        );

        this.axios.put('warranty-seal', {
          'bulkWarrantySealId': this.form.bulkWarrantySealId,
          'deliveryDate': momentDeliveryDate.toISOString(),
          'warrantySealQty': this.form.warrantySealQty,
          'runNo': this.form.runNo
        }).then(() => {
          (this.$refs.modalAddWarrantySeal as any).hide();
        })
          .catch((err:AxiosError) => {
            if (err.response && err.response.data && err.response.data.message) {
              this.formErrorMessage = err.response.data.message;
            }
            if (this.formErrorMessage === '') {
              this.formErrorMessage = 'Error updating warranty seal.';
            }
          }).finally(() => {
            this.form.isSubmitting = false;
            this.getList();
          });
      }
    },
    updateTableSort (tableSort: TableSortAndFilter) {
      updateTableSort(this.tableFilter, tableSort);
      this.getList();
    }
  }
});
