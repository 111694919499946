

































































































































/* global $ */
import { VeeValidateObserverRef } from '@/types/libraries/VeeValidateObserverRef';
import { ApiValidationErrorResponse } from '@/types/api/Response';
import Vue from 'vue';
import mixinsVue from './mixins.vue';
export default Vue.extend({
  'name': 'ModalAddWarrantySealToDealer',
  'mixins': [mixinsVue],
  'props': {
    'value': {
      'type': Object,
      'required': true
    },
    'errorMessage': {
      'type': String,
      'default': ''
    }
  },
  data () {
    return {
      'isAdd': true
    };
  },
  mounted () {
    // @ts-expect-error: Ignore error explicitly.
    $(this.$refs.inputDeliveryDate).daterangepicker({
      'singleDatePicker': true,
      'autoApply': true,
      'locale': {
        'format': 'DD/MM/YYYY'
      }
    });

    $(this.$refs.inputDeliveryDate).on(
      'apply.daterangepicker',
      (ev, picker) => {
        this.value.deliveryDateUi = picker.startDate.format('DD/MM/YYYY');
      }
    );
  },
  'methods': {
    onSubmit () {
      this.$emit('submit');
    },
    update () {
      this.isAdd = false;
      (this.$refs.form as any).reset();
      (this as any).show();
    },
    create () {
      this.isAdd = true;
      (this.$refs.form as any).reset();
      (this as any).show();
    },
    setErrors (errors: ApiValidationErrorResponse) {
      (this.$refs.form as VeeValidateObserverRef).setErrors(errors);
    }
  }
});
